
.Navigation {

  &__brand {
    width: 118px;
    min-width: 118px;

    @media (max-width: $break-small) {
      width: 86px;
      min-width: 86px;
      height: 40px;
    }
  }

  &__menuItem--selected:after {
    background-image: url("~@amf/shared/assets/icon-cross-green.svg") !important;
  }

  &--white &__menuItem:before {
    background-image: url("~@amf/shared/assets/icon-cross-green.svg") !important;
  }
}
