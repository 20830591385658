

h1,
.h1 {

  &:before {
    background: url("~@amf/shared/assets/icon-cross-green.svg") no-repeat center;
    background-size: 24px 24px;
  }
}
